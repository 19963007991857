<template>
  <VBreadcrumbs
    v-bind="{ ...$attrs, ...$props }"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    v-on="$listeners"
  >
    <template v-slot:item="{ item }">
      <VBreadcrumbsItem
        v-bind="item"
        :exact="true"
      >
        {{ item.text }}
      </VBreadcrumbsItem>
    </template>
  </VBreadcrumbs>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Route } from 'vue-router';
import { VBreadcrumbs } from 'vuetify/lib';

interface BreadcrumbsItem {
  text: string | number;
  disabled?: boolean;
  exact?: boolean;
  href?: string;
  link?: boolean;
  to?: string | Route;
}

export default Vue.extend({
  name: 'TTBreadcrumbs',
  extends: VBreadcrumbs,
  props: {
    dataTest: { type: String, default: 'tt-breadcrumbs' },
    dataTestLabel: { type: String, default: '' },
    dataTestValue: { type: String, default: '' },
    items: {
      type: Array as PropType<BreadcrumbsItem[]>,
      default: () => [] as BreadcrumbsItem[],
    },
    divider: {
      type: String,
      default: '•',
    },
  },
});
</script>

<style src="./TTBreadcrumbs.scss" lang="scss" />
