<template>
  <div class="d-flex">
    <TTAvatar
      id="tt-profile-menu"
      small
      :src="userAvatar"
      :text="userInitials"
      data-test-value="user-avatar"
    />

    <VMenu
      :disabled="showProfileMenu"
      content-class="tt-profile-menu-tooltip__wrapper"
      bottom
      offset-y
      left
      attach
      open-on-hover
      :max-width="width"
      activator="#tt-profile-menu"
    >
      <div class="tt-profile-menu-tooltip">
        <div class="tt-text-body-2 line-clamp line-clamp-1">
          {{ userName }}
        </div>
        <div
          v-if="currentCompany"
          class="tt-text-caption line-clamp line-clamp-2"
        >
          {{ currentCompany.name }}
        </div>
      </div>
    </VMenu>

    <VMenu
      :value="showProfileMenu"
      content-class="tt-profile-menu__wrapper"
      bottom
      offset-y
      left
      attach
      open-on-click
      :close-on-content-click="false"
      :max-width="width"
      :min-width="width"
      activator="#tt-profile-menu"
      @input="toggleProfileMenu"
    >
      <slot
        v-if="showHeader"
        name="prepend"
      >
        <div class="prepend-slot">
          <div>
            <div
              class="tt-text-body-2 line-clamp line-clamp-2"
              data-test-value="my-name"
            >
              {{ userName }}
            </div>
            <div
              v-if="currentCompany"
              class="tt-text-caption line-clamp line-clamp-2"
              data-test-value="selected-company"
            >
              {{ currentCompany.name }}
            </div>
          </div>

          <VSpacer />

          <TTAvatar
            v-if="companyAvatar"
            class="ml-3"
            :src="companyAvatar"
          />
        </div>
      </slot>
      <VList
        v-if="companies.length > 1"
        class="px-0 py-1 companies-list"
      >
        <VListItem
          v-for="company in companies"
          :key="company.id"
          data-test="list-item"
          :data-test-value="company.id"
          @click="$emit('select-company', company)"
        >
          <VListItemIcon class="justify-center mr-4 my-auto">
            <VIcon
              v-if="currentCompany.id === company.id"
              color="tt-light-green vibrant"
              size="12"
            >
              fa fa-check
            </VIcon>
          </VListItemIcon>
          <div class="d-flex align-center list-item-text__wrapper">
            <div
              class="tt-text-body-2 line-clamp line-clamp-2"
            >
              {{ company.name }}
            </div>
          </div>
        </VListItem>
      </VList>
      <VList
        v-if="actions.length"
        class="px-0 py-1"
      >
        <VListItem
          v-for="action in actions"
          :key="action.id"
          data-test="list-item"
          :data-test-value="action.id"
          @click="$emit(action.id, action)"
        >
          <VListItemIcon class="justify-center mr-4 my-auto">
            <VIcon
              :color="action.color"
              :size="actionsIconSize"
            >
              {{ action.icon }}
            </VIcon>
          </VListItemIcon>
          <div class="d-flex align-center list-item-text__wrapper">
            <div
              :class="action.color ? `${action.color}--text` : ''"
              class="tt-text-body-2 line-clamp line-clamp-2"
            >
              {{ action.text }}
            </div>
          </div>
        </VListItem>
      </VList>
    </VMenu>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import TTAvatar from '../uikit/components/TTAvatar/TTAvatar.vue';

interface Company {
  // Название компании
  name: string;
  isAdmin?: boolean;
  id?: string;
}

interface Action {
  // событие которое будет сгенерировано при клике на пункт меню
  id: string;
  // иконка слева от пункта меню
  icon:string ;
  // текст пункта меню
  text:string;
  // текст пункта меню
  color?:string
}

export default Vue.extend({
  name: 'AppProfileMenu',
  components: { TTAvatar },
  props: {
    width: {
      type: Number,
      default: 320,
    },
    userName: {
      type: String,
      required: true,
    },
    userInitials: {
      type: String,
      default: null,
    },
    actionsIconSize: {
      type: [Number, String],
      default: 19,
    },
    userAvatar: {
      type: String,
      default: null,
    },

    companies: {
      type: Array as PropType<Company[]>,
      default: () => [] as Company[],
    },

    currentCompany: {
      type: Object as PropType<Company>,
      default: null,
    },
    companyAvatar: {
      type: String,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },

    actions: {
      type: Array as PropType<Action[]>,
      default: () => [] as Action[],
    },
    profileMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileMenuLocal: false,
    };
  },
  computed: {
    showProfileMenu(): boolean {
      return this.profileMenu || this.profileMenuLocal;
    },
  },
  methods: {
    toggleProfileMenu(event: boolean) {
      if (event === this.profileMenuLocal) return;
      this.profileMenuLocal = event;
      this.$emit('input', event);
    },
  },
});
</script>

<style lang="scss" scoped>
$tt-profile-menu-tooltip-background: rgba(map-get($tt-light-mono-100, 'base'), 0.8);

#tt-profile-menu{
  cursor: pointer;
}

.companies-list,
.prepend-slot,
.list-item-text__wrapper{
  border-bottom: 1px solid map-get($tt-light-mono-8, 'base');
}

.prepend-slot{
  display:flex;
  align-items: center;
  padding: 16px;
}

.tt-profile-menu__wrapper {
  max-height: calc(100vh - 36px);
  border-radius: 4px!important;
  border: 1px solid map-get($tt-light-mono-8, 'base');
  background-color: map-get($tt-light-mono-0, 'base');
  box-sizing: border-box;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
  .list-item-text__wrapper{
    height: 54px;
    width: 100%;
  }
  .v-list-item:last-child .list-item-text__wrapper{
    border-bottom: 0;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &-1{
    -webkit-line-clamp:1;
  }
  &-2{
    -webkit-line-clamp:2;
  }
}

.tt-profile-menu-tooltip__wrapper{
  border-radius: 4px!important;

  .tt-profile-menu-tooltip {
    background: $tt-profile-menu-tooltip-background !important;
    padding: 8px 12px;
    margin-top: 4px;
    border-radius: 4px!important;
    color: map-get($tt-light-mono-0, 'base');

    &:before {
      border-color: transparent transparent $tt-profile-menu-tooltip-background transparent !important;
      content: "";
      position: absolute;
      top: -4px;
      right: 14px;
      border: 4px solid;
    }
  }
}

.companies-list {
  min-height: 100px;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}
</style>
