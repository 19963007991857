<template>
  <TTAppBar
    app
    clipped-left
    :clipped-right="clippedRight"
  >
    <TTToolbarTitle class="tt-flex align-center">
      <slot name="brand">
        <template
          v-if="logoUrl"
        >
          <a
            v-if="isExternalLogoUrl"
            :href="logoUrl"
          >
            <VImg
              :src="logo"
              class="logo"
            />
          </a>
          <RouterLink
            v-else
            :to="logoUrl"
          >
            <VImg
              :src="logo"
              class="logo"
            />
          </RouterLink>
        </template>
        <VImg
          v-else
          :src="logo"
          class="logo"
        />
        <div
          v-if="brandName"
          class="tt-text-caption ml-3"
          data-test="brand-name"
        >
          {{ brandName }}
        </div>
      </slot>
      <slot name="label">
        <div
          v-if="isLabelVisible"
          data-test="label"
          class="
            tt-app-navbar__label
            tt-text-footnote
            tt-light-mono-8
            tt-light-mono-64--text
            mb-1 ml-1 px-1
            rounded-sm
          "
        >
          {{ labelText }}
        </div>
      </slot>
    </TTToolbarTitle>

    <div class="d-flex align-center">
      <TTBtn
        v-for="route in items"
        :key="route.text"
        text
        :depressed="false"
        color="tt-light-mono-100"
        :href="isExternalLink(route)"
        :to="route.to"
        :class="{ 'v-btn--active' : currentApp === route.href }"
        :data-test-value="route.testValue"
        :data-test-label="route.testLabel"
        height="46"
        class="tt-text-caption font-weight-regular px-0 mr-6 custom-item"
        @click="$emit('click-module-btn', route)"
      >
        {{ route.text }}
      </TTBtn>
    </div>

    <VSpacer />

    <slot name="company" />
  </TTAppBar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import logo from '../../assets/potok-logo.svg';

type Route = {
  text: string
  href: string;
  to?: string;
  event?: unknown;
  testLabel?: string;
  testValue?: string;
}

export default Vue.extend({
  name: 'AppNavbar',
  props: {
    currentApp: {
      type: String,
      default: '/',
    },
    items: {
      type: Array as PropType<Route[]>,
      default: () => [] as Route[],
    },
    brandUrl: {
      type: String,
      default: null,
    },
    clippedRight: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    brandName: {
      type: String,
      default: '',
    },
    logoUrl: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    isLabelVisible() {
      return Boolean(this.labelText || this.$scopedSlots.label);
    },
    isExternalLogoUrl() {
      if (typeof this.logoUrl === 'string') {
        return /^(https?:)?\/\//i.test(this.logoUrl);
      }
      return false;
    },
  },
  methods: {
    isExternalLink(route: Route) {
      return (!route.event && route.href) || undefined;
    },
  },
});
</script>

<style lang="scss" scoped>
.logo {
  margin-right: 81px;
}
.company-subtitle {
  font-size: 0.625rem;
}

.custom-item {
  min-width: auto !important;
}

.custom-item.v-btn {
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: border-bottom-color 0.2s linear;
}

.custom-item.v-btn:hover,
.custom-item.v-btn--active {
  border-bottom: 1px solid map-get($tt-light-mono-100, 'base');
}

.tt-app-navbar__label {
  padding-top: $spacer / 4;
  padding-bottom: $spacer / 4;
}
</style>
