import { decodeJwt } from 'jose';
import Cookies from 'js-cookie';

class GSIToken {
  static DEFAULT_OPTIONS = {
    name: 'gsi_access_token',
  };

  constructor(options = GSIToken.DEFAULT_OPTIONS) {
    this.cookies = Cookies;
    this.options = options;
  }

  set(value) {
    const { name } = this.options;
    const decoded = decodeJwt(value);
    const params = {
      domain: new URL(window.location).hostname.replace(/.*(talenttechlab\.(com|org))$/, '.$1'),
      // TODO: увеличить время жизни? В токене хранится информация о пользователе,
      // либо сохранять ее в другом месте (localstorage)
      expires: new Date(decoded.exp * 1000 + (24 * 60 * 60 * 1000)),
    };

    this.cookies.set(name, value, params);
  }

  get() {
    const { name } = this.options;

    return this.cookies.get(name);
  }

  remove() {
    const { name } = this.options;
    const params = {
      domain: new URL(window.location).hostname.replace(/.*(talenttechlab\.(com|org))$/, '.$1'),
    };

    return this.cookies.remove(name, params);
  }

  isExpired() {
    const value = this.get();
    if (!value) return true;
    const decoded = decodeJwt(value);

    if (decoded === null) return true;

    // Note: нужно умножить на 1000, т.к. JS требует миллисекунды, а в jwt секунды. (^_^)
    return new Date(decoded?.exp * 1000 + (24 * 60 * 60 * 1000)) < new Date();
  }
}

export default new GSIToken();
