/* eslint-disable max-len, vue/max-len */
import { AppCoreAsideLeft as AppAsideLeft, AppNav, HolyGrailLayout } from '@front.backoffice/shared';
import * as Names from './app.routes.names';

const MODULE_PREFIX = '/app/core';

export default [
  {
    path: MODULE_PREFIX,
    component: HolyGrailLayout,
    children: [
      {
        path: '',
        name: Names.R_CORE_HOME,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/app/Home.vue'),
        },
      },
      {
        path: 'accounts',
        name: Names.R_ACCOUNTS_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/Dashboard.vue'),
        },
      },
      {
        path: 'accounts/accounts',
        name: Names.R_ACCOUNTS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/account/AccountList.vue'),
        },
      },
      {
        /**
         * Note: Этот роут должен быть выше роутов с параметрами на втором уровне
         * потому что динамический параметр забирает в себя sys-company при перезагрузке страницы
         */
        path: 'accounts/companies',
        name: Names.R_ACCOUNT_SYS_COMPANIES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/company/SysCompanyList.vue'),
        },
      },
      {
        path: 'accounts/:accountId',
        name: Names.R_ACCOUNT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/account/Account.vue'),
        },
      },
      {
        path: 'accounts/:id/edit',
        name: Names.R_ACCOUNT_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/account/AccountItem.vue'),
        },
      },
      {
        path: 'accounts/:accountId/user/add',
        name: Names.R_ACCOUNT_USER_ADD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/account/UserAdd.vue'),
        },
      },
      {
        path: 'accounts/:accountId/user/remove',
        name: Names.R_ACCOUNT_USER_REMOVE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/account/UserRemove.vue'),
        },
      },

      {
        path: 'accounts/companies/:id',
        name: Names.R_ACCOUNT_SYS_COMPANY,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/company/SysCompanyItem.vue'),
        },
      },
      {
        path: 'accounts/companies/:companyId/user/add',
        name: Names.R_ACCOUNT_SYS_COMPANY_USER_ADD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/company/UserAdd.vue'),
        },
      },
      {
        path: 'accounts/companies/:companyId/user/remove',
        name: Names.R_ACCOUNT_SYS_COMPANY_USER_REMOVE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/company/UserRemove.vue'),
        },
      },

      {
        path: 'accounts/companies/:id/edit',
        name: Names.R_ACCOUNT_SYS_COMPANY_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/accounts/company/SysCompanyItemEdit.vue'),
        },
      },

      {
        path: 'authentication',
        name: Names.R_AUTHENTICATION_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/Dashboard.vue'),
        },
      },

      {
        path: 'authentication/users/create',
        name: Names.R_AUTHENTICATION_USERS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/UsersCreate.vue'),
        },
      },

      {
        path: 'authentication/users/search',
        name: Names.R_AUTHENTICATION_USERS_SEARCH,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/UsersSearch.vue'),
        },
      },

      {
        path: 'authentication/users/:userId',
        name: Names.R_AUTHENTICATION_USER,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/User.vue'),
        },
      },

      {
        path: 'authentication/users/:userId/phones/create',
        name: Names.R_AUTHENTICATION_USER_PHONES_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/PhonesCreate.vue'),
        },
      },

      {
        path: 'authentication/users/:userId/push/send',
        name: Names.R_AUTHENTICATION_USER_PUSH_SEND,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/PushSend.vue'),
        },
      },

      {
        path: 'authentication/users/:userId/emails/create',
        name: Names.R_AUTHENTICATION_USER_EMAILS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/EmailsCreate.vue'),
        },
      },

      {
        path: 'authentication/users/:userId/edit',
        name: Names.R_AUTHENTICATION_USER_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/UserEdit.vue'),
        },
      },

      {
        path: 'authentication/users/:userId/add-sap-id',
        name: Names.R_AUTHENTICATION_ADD_SAP_ID,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/authentication/UserAddSapId.vue'),
        },
      },

      {
        path: 'competency-matrix',
        name: Names.R_COMPETENCY_MATRIX_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Dashboard.vue'),
        },
      },
      {
        path: 'competency-matrix/skills',
        name: Names.R_COMPETENCY_MATRIX_SKILLS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Skills.vue'),
        },
      },
      {
        path: 'competency-matrix/skills/create',
        name: Names.R_COMPETENCY_MATRIX_SKILL_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/SkillCreate.vue'),
        },
      },
      {
        path: 'competency-matrix/skills/:skillId',
        name: Names.R_COMPETENCY_MATRIX_SKILL,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Skill.vue'),
        },
      },
      {
        path: 'competency-matrix/skills/:skillId/edit',
        name: Names.R_COMPETENCY_MATRIX_SKILL_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/SkillEdit.vue'),
        },
      },
      {
        path: 'competency-matrix/positions',
        name: Names.R_COMPETENCY_MATRIX_POSITIONS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Positions.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/user-matrix',
        name: Names.R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionsUserMatrix.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/user-matrix/users/:userId/companies/:companyId',
        name: Names.R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionsUserMatrixView.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/company-matrix',
        name: Names.R_COMPETENCY_MATRIX_POSITIONS_COMPANY_MATRIX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionsCompanyMatrix.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/company-matrix/companies/:companyId',
        name: Names.R_COMPETENCY_MATRIX_POSITIONS_COMPANY_MATRIX_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionsCompanyMatrixView.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/create',
        name: Names.R_COMPETENCY_MATRIX_POSITION_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionCreate.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/:positionId',
        name: Names.R_COMPETENCY_MATRIX_POSITION,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Position.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/:positionId/edit',
        name: Names.R_COMPETENCY_MATRIX_POSITION_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionEdit.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/:positionId/competencies',
        name: Names.R_COMPETENCY_MATRIX_POSITION_COMPETENCIES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionCompetencies.vue'),
        },
      },
      {
        path: 'competency-matrix/positions/:positionId/users',
        name: Names.R_COMPETENCY_MATRIX_POSITION_USERS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/PositionUsers.vue'),
        },
      },
      {
        path: 'competency-matrix/competencies',
        name: Names.R_COMPETENCY_MATRIX_COMPETENCIES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Competencies.vue'),
        },
      },
      {
        path: 'competency-matrix/competencies/create',
        name: Names.R_COMPETENCY_MATRIX_COMPETENCY_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/CompetencyCreate.vue'),
        },
      },
      {
        path: 'competency-matrix/competencies/:competencyId',
        name: Names.R_COMPETENCY_MATRIX_COMPETENCY,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Competency.vue'),
        },
      },
      {
        path: 'competency-matrix/competencies/:competencyId/edit',
        name: Names.R_COMPETENCY_MATRIX_COMPETENCY_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/CompetencyEdit.vue'),
        },
      },
      {
        path: 'competency-matrix/competencies/:competencyId/skills',
        name: Names.R_COMPETENCY_MATRIX_COMPETENCY_SKILLS,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/CompetencySkills.vue'),
        },
      },
      {
        path: 'competency-matrix/instruments/:instrumentId',
        name: Names.R_COMPETENCY_MATRIX_INSTRUMENT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/Instrument.vue'),
        },
      },
      {
        path: 'competency-matrix/instruments/:instrumentId/edit',
        name: Names.R_COMPETENCY_MATRIX_INSTRUMENT_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/competency-matrix/InstrumentEdit.vue'),
        },
      },

      {
        path: 'gogha',
        name: Names.R_GOGHA_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/gogha/Dashboard.vue'),
        },
      },
      {
        path: 'gogha/upload',
        name: Names.R_GOGHA_UPLOAD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/gogha/GoghaUpload.vue'),
        },
      },

      {
        path: 'invitations',
        name: Names.R_INVITATIONS_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/invitations/Dashboard.vue'),
        },
      },
      {
        path: 'invitations/invitations',
        name: Names.R_INVITATIONS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/invitations/Invitations.vue'),
        },
      },
      {
        path: 'invitations/create',
        name: Names.R_INVITATION_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/invitations/InvitationsCreate.vue'),
        },
      },
      {
        path: 'invitations/:invitationId',
        name: Names.R_INVITATION,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/invitations/Invitation.vue'),
        },
      },
      {
        path: 'invitations/:invitationId/edit',
        name: Names.R_INVITATION_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/invitations/InvitationsEdit.vue'),
        },
      },

      {
        path: 'license',
        name: Names.R_LICENSES_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/license/Dashboard.vue'),
        },
      },
      {
        path: 'license/license',
        name: Names.R_LICENSES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/license/LicenseList.vue'),
        },
      },
      {
        path: 'license/apps',
        name: Names.R_LICENSE_APPS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/license/apps/AppList.vue'),
        },
      },
      {
        path: 'license/:id',
        name: Names.R_LICENSE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/license/LicenseItem.vue'),
        },
      },

      {
        path: 'meta',
        name: Names.R_META_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/meta/Dashboard.vue'),
        },
      },
      {
        path: 'meta/search',
        name: Names.R_META_SEARCH,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/meta/MetaSearch.vue'),
        },
      },
      {
        path: 'meta/create',
        name: Names.R_META_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/meta/MetaCreate.vue'),
        },
      },
      {
        path: 'meta/:userId/:serviceId',
        name: Names.R_META,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/meta/MetaView.vue'),
        },
      },
      {
        path: 'meta/:userId/:serviceId/edit',
        name: Names.R_META_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/meta/MetaEdit.vue'),
        },
      },

      {
        path: 'orgstructure',
        name: Names.R_ORGSTRUCTURE_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/Dashboard.vue'),
        },
      },
      {
        path: 'orgstructure/companies',
        name: Names.R_ORGSTRUCTURE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgMain.vue'),
        },
      },

      {
        path: 'orgstructure/companies/:id',
        name: Names.R_ORGSTRUCTURE_COMPANY,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgCompany.vue'),
        },
      },
      {
        path: 'orgstructure/companies/:id/teams/add',
        name: Names.R_ORGSTRUCTURE_COMPANY_TEAM_ADD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/AddTeamToCompanyForm.vue'),
        },
      },
      {
        path: 'orgstructure/companies/:companyId/teams/:teamId',
        name: Names.R_ORGSTRUCTURE_COMPANY_TEAM,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgTeam.vue'),
        },
      },
      {
        path: 'orgstructure/companies/:companyId/teams/:teamId/edit',
        name: Names.R_ORGSTRUCTURE_COMPANY_TEAM_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgTeamEdit.vue'),
        },
      },
      {
        path: 'orgstructure/companies/:companyId/teams/:teamId/users/add',
        name: Names.R_ORGSTRUCTURE_COMPANY_TEAM_USERS_ADD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/AddUserToTeamForm.vue'),
        },
      },
      {
        path: 'orgstructure/companies/:companyId/teams/:teamId/children/add',
        name: Names.R_ORGSTRUCTURE_COMPANY_TEAM_CHILDREN_ADD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/AddChildToTeamForm.vue'),
        },
      },

      {
        path: 'orgstructure/user/:personId/companies/:companyId',
        name: Names.R_ORGSTRUCTURE_USER_COMPANY,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgUserCompany.vue'),
        },
        redirect: { name: Names.R_ORGSTRUCTURE_USER_COMPANY_HIERARCHY },
        children: [
          {
            path: 'hierarchy',
            name: Names.R_ORGSTRUCTURE_USER_COMPANY_HIERARCHY,
            component: () => import('./views/org/UserCompanyHierarchy.vue'),
          },
          {
            path: 'competency',
            name: Names.R_ORGSTRUCTURE_USER_COMPANY_COMPETENCY_GROUPS,
            component: () => import('./views/org/UserCompanyCompetencyGroups.vue'),
          },
          {
            path: 'another',
            name: Names.R_ORGSTRUCTURE_USER_COMPANY_ANOTHER_CONNECTS,
            component: () => import('./views/org/UserCompanyAnotherConnects.vue'),
          },
        ],
      },

      {
        path: 'orgstructure/user/:personId/companies/:companyId/connect',
        name: Names.R_ORGSTRUCTURE_USER_COMPANY_CONNECT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/CreateLinkToPersonForm.vue'),
        },
      },

      {
        path: 'orgstructure/position/create',
        name: Names.R_ORGSTRUCTURE_POSITION_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgPositionCreate.vue'),
        },
      },

      {
        path: 'orgstructure/position/:positionId/edit',
        name: Names.R_ORGSTRUCTURE_POSITION_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgPositionEdit.vue'),
        },
      },

      {
        path: 'orgstructure/position/:positionId/view',
        name: Names.R_ORGSTRUCTURE_POSITION_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgPositionView.vue'),
        },
      },

      {
        path: 'orgstructure/position/search',
        name: Names.R_ORGSTRUCTURE_POSITION_SEARCH,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgPositionSearch.vue'),
        },
      },
      {
        path: 'orgstructure/position/link',
        name: Names.R_ORGSTRUCTURE_POSITION_TO_POSITION,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgPositionLink.vue'),
        },
      },
      {
        path: 'orgstructure/competency-group/link',
        name: Names.R_ORGSTRUCTURE_POSITION_TO_COMPETENCY_GROUP,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/org/OrgPositionGroupLink.vue'),
        },
      },

      {
        path: 'sysadmin',
        name: Names.R_SYSADMIN_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/sysadmin/Dashboard.vue'),
        },
      },
      {
        path: 'sysadmin/routes',
        name: Names.R_SYSADMIN_ROUTES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/sysadmin/RouteList.vue'),
        },
      },
      {
        path: 'sysadmin/routes/:routeId/edit',
        name: Names.R_SYSADMIN_ROUTES_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/sysadmin/RoutesEdit.vue'),
        },
      },
      {
        path: 'sysadmin/routes/:routeId/view',
        name: Names.R_SYSADMIN_ROUTES_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/sysadmin/RoutesView.vue'),
        },
      },
      {
        path: 'sysadmin/routes/create',
        name: Names.R_SYSADMIN_ROUTES_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/sysadmin/RoutesCreate.vue'),
        },
      },

      {
        path: 'notification',
        name: Names.R_NOTIFICATION_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/notification/Dashboard.vue'),
        },
      },
      {
        path: 'notification/templates',
        name: Names.R_NOTIFICATION_TEMPLATES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/notification/TemplatesList.vue'),
        },
      },
      {
        path: 'notification/templates/:templateName/edit',
        name: Names.R_NOTIFICATION_TEMPLATES_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/notification/TemplatesEdit.vue'),
        },
      },
      {
        path: 'notification/templates/:templateName/view',
        name: Names.R_NOTIFICATION_TEMPLATES_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/notification/TemplatesView.vue'),
        },
      },
      {
        path: 'notification/templates/create',
        name: Names.R_NOTIFICATION_TEMPLATES_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/notification/TemplatesCreate.vue'),
        },
      },
      {
        path: 'notification/templates/sandbox',
        name: Names.R_NOTIFICATION_TEMPLATES_SANDBOX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/notification/TemplatesSandbox.vue'),
        },
      },
    ],
  },

  {
    path: `${MODULE_PREFIX}/v2`,
    component: HolyGrailLayout,
    children: [
      {
        path: 'accounts/create',
        name: Names.R_ACCOUNT_V2_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/v2/company/CompanyCreate.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/license/create',
        name: Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE_CREATE,
        props: { default: true },
        components: {
          default: () => import('./views/v2/company/CompanyLicenseCreate.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId',
        props: { default: true },
        name: Names.R_ACCOUNT_V2_COMPANY_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/v2/company/CompanyView.vue'),
        },
        redirect: { name: Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE },
        children: [
          {
            path: 'license',
            name: Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE,
            props: true,
            component: () => import('./views/v2/company/CompanyLicense.vue'),
          },
          {
            path: 'orgstructure/teams',
            name: Names.R_ACCOUNT_V2_COMPANY_VIEW_TEAM_LIST,
            props: true,
            component: () => import('./views/v2/team/TeamList.vue'),
          },
          {
            path: 'import',
            name: Names.R_ACCOUNT_V2_COMPANY_IMPORT_INDEX,
            props: true,
            component: () => import('./views/v2/company/CompanyImport.vue'),
          },
          {
            path: 'admins',
            name: Names.R_ACCOUNT_V2_COMPANY_VIEW_ADMINS,
            props: true,
            component: () => import('./views/v2/company/CompanyAdmins.vue'),
          },
        ],
      },
      {
        path: 'accounts/:accountId/companies/:companyId/import/:importId/upload',
        props: { default: true },
        name: Names.R_ACCOUNT_V2_COMPANY_IMPORT_UPLOAD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/import/ImportUpload.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/import/:importId/processing',
        props: { default: true },
        name: Names.R_ACCOUNT_V2_COMPANY_IMPORT_PROCESSING,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/import/ImportProcessing.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/orgstructure/teams/create',
        props: { default: true },
        name: Names.R_ACCOUNT_V2_COMPANY_TEAM_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/v2/team/TeamCreateView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/orgstructure/teams/:teamId/edit',
        props: { default: true },
        name: Names.R_ACCOUNT_V2_COMPANY_TEAM_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/v2/team/TeamEditView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/orgstructure/teams/:teamId',
        props: { default: true },
        name: Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/v2/team/TeamView.vue'),
        },
        redirect: { name: Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW_STAFF_POSITION },
        children: [
          {
            path: 'staff-position',
            name: Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW_STAFF_POSITION,
            props: true,
            component: () => import('./views/v2/team/StaffPositionList.vue'),
          },
        ],
      },
    ],
  },
];
