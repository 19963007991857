import { render, staticRenderFns } from "./TTAlert.vue?vue&type=template&id=d7c0b0c0&scoped=true"
import script from "./TTAlert.vue?vue&type=script&lang=js"
export * from "./TTAlert.vue?vue&type=script&lang=js"
import style0 from "./TTAlert.vue?vue&type=style&index=0&id=d7c0b0c0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7c0b0c0",
  null
  
)

export default component.exports